<template>
    <modal ref="modalHistorialPrecio" titulo="Historial cambio precio" :height="70" :fix-height="70" no-aceptar no-cancelar tamano="modal-lg">
        <div class="row mx-0">
            <div class="col-12 text-general f-600">
                <span class="f-600">
                    CEDIS:
                </span>   
                {{ nombre }}
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12 px-0">
                <el-table
                :data="listado_paginado"
                stripe
                header-row-class-name="text-general f-600 f-16"
                style="width: 100%"
                >
                    <el-table-column
                    prop="responsable"
                    label="Responsable"
                    />
                    
                    <el-table-column
                    class-name="text-center"
                    label="Valor"
                    >
                        <template slot-scope="scope">                            
                            <span>{{ convertMoney(scope.row.valor,idm_moneda) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="50">
                        <template slot-scope="scope">
                            <i v-if="scope.row.herencia" class="icon-gestion-admin text-general f-18" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Fecha de cambio" class-name="text-center">
                        <template slot-scope="scope">
                            {{ scope.row.created_at | helper-fecha("DD MMM YYYY h:mm a") }}
                        </template>
                    </el-table-column>
                </el-table>
                <div class="row mx-0 justify-center py-2">
                    <el-pagination
                    class="paginar-general"
                    layout="prev, pager, next"
                    :page-size="per_page_items"
                    :total="listado.length"
                    @current-change="changeCurrentPage"
                    />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import ValorMinimo from '~/services/configurar/admin/valorMinimo'
export default {
    data(){
        return {
            nombre : '',
            listado: [],
            listado_paginado:[],
            idm_moneda : '',
            current_page : 1, 
            per_page_items : 25
        }
    },
    methods:{
        toggle(){
            this.$refs.modalHistorialPrecio.toggle();
        },
        async HistorialValores(id_cedis,nombre,idm_moneda){
            try {
                this.nombre = nombre
                this.idm_moneda = idm_moneda
                const {data} = await ValorMinimo.GetHistorialValores(id_cedis)
                this.listado = data
                console.log(this.paginator(this.listado))
                this.listado_paginado = this.paginator(this.listado).data
            } catch (e){
                this.error_catch(e)
            }
        },
        paginator(items){
            let page = this.current_page || 1,
                per_page = this.per_page_items || 10,
                offset = (page - 1) * per_page,

                paginatedItems = items.slice(offset).slice(0, this.per_page_items),
                total_pages = Math.ceil(items.length / per_page);

            return {
                page: page,
                per_page: per_page,
                pre_page: page - 1 ? page - 1 : null,
                next_page: (total_pages > page) ? page + 1 : null,
                total: items.length,
                total_pages: total_pages,
                data: paginatedItems
            };
        },
        changeCurrentPage(page){
            this.current_page = page
            this.listado_paginado = this.paginator(this.listado).data
        }
    }
}
</script>